<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="计划名称" prop="title">
						<el-input v-model="form.title"/>
					</el-form-item>
					<el-form-item label="计划类型" prop="type">
						<el-select class="el-block" v-model="form.type">
							<el-option :value="item.id" v-for="(item,index) in column_option.type" :key="index" :label="item.title"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="选择设备" prop="good_id">
						<form-api v-model="form.good_id" :api="good.all" :multiple="true"/>
					</el-form-item>
					<el-form-item label="周期" prop="step">
						<el-select class="el-block" v-model="form.step" @change="clearTime">
							<el-option :value="item.id" v-for="(item,index) in column_option.step" :key="index" :label="item.title"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="周期时间" prop="step_time">
						<el-date-picker v-if="form.step === 1" type="dates" value-format="yyyy-MM-dd" v-model="form.step_time" :picker-options="pickerOptions"/>
						<el-select v-if="form.step === 2" class="el-block" multiple v-model="form.step_time">
							<el-option :value="item.id" v-for="(item,index) in column_option.step_time_week" :key="index" :label="item.title"></el-option>
						</el-select>
						<el-select v-if="form.step === 3" class="el-block" multiple v-model="form.step_time">
							<el-option :value="item" v-for="(item,index) in 31" :key="index" :label="item+'日'"></el-option>
						</el-select>
						<el-cascader class="el-block" v-if="form.step === 4" v-model="form.step_time" :options="column_option.step_time_year" :props="{value:'id',label:'title',multiple:true}"></el-cascader>
					</el-form-item>
					<el-form-item label="操作说明" prop="message">
						<form-editor v-model="form.message"/>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio-group v-model="form.status">
							<el-radio v-for="(item,index) in column_option.status" :key="index" :label="item.id">{{item.title}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="创建时间" prop="create_time">
						<form-date-picker v-model="form.create_time" type="datetime" />
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>
<script>
import {plan as api, good} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";
export default {
	mixins:[MixinCreate],
	data(){
		return {
			api,
			pickerOptions:{
				disabledDate(time){
					return time.getTime() < Date.now();
				}
			}
		}
	},
	computed:{
		good() {
			return good
		},
		rules(){
			return {
				title:[
					{required:true,message:"请输入标题"}
				],
				type:[
					{required:true,message:"请选择类型"}
				],
				good_id:[
					{required:true,message:"请选择设备"}
				],
				step:[
					{required:true,message:"请选择周期"}
				],
				step_time:[
					{required:true,message:"请选择周期时间"}
				],
				message:[
					{required:true,message:"请输入操作说明"}
				],
			}
		}
	},
	methods:{
		decodeData(data){
			data.good_id = data.good_id?.split(',') || [];
			data.step_time = data.step_time?.split(',') || [];
			data.good_id = data.good_id.map((item)=>{
				return Number(item)
			})
			data.step_time = data.step_time.map((item)=>{
				if(data.step ===2 || data.step === 3){
					return Number(item);
				}else if(data.step === 4){
					return  item ? item?.split('-') : '';
				}else{
					return item;
				}
			})
			return data;
		},
		encodeData(data){
			data.good_id = data.good_id?.join(',') || '';
			if(data.step === 4){
				if(data?.step_time?.length > 0){
					data.step_time = data.step_time.map((item,index)=>{
						return item.join('-')
					})
				}
			}
			data.step_time = data.step_time?.join(',') || '';
			return data;
		},
		clearTime(){
			this.$set(this.form,'step_time',null);
		},
	}
}
</script>
