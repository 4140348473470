<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<el-button class="el-icon-plus" @click="create">创建</el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
						<template #reference>
							<el-button icon="el-icon-delete">删除</el-button>
						</template>
					</el-popconfirm>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #column="{scope,item}">
						<div v-if="item.type === 'step_time'">{{ stepTimeText(scope, item) }}</div>
					</template>
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" @click="editor(scope.row)">编辑</el-link>
							<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
								<template #reference>
									<el-link type="primary">删除</el-link>
								</template>
							</el-popconfirm>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<table-import v-if="import_visible" ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
		<components-create v-if="create_visible" ref="createRef" :title="title" :column_option="column_option" @success="all" @close="closeCreate"/>
	</main>
</template>
<script>
import ComponentsCreate from "./Create.vue"
import {MixinIndex} from "@/service/admin/mixinIndex";
import {good, plan as api} from "@/api/admin";
import {inArray} from "@/common/array";

export default {
	components: {ComponentsCreate},
	mixins: [MixinIndex],
	data() {
		return {
			api,
			title: "计划",
			column_option: {
				type: [{id: 2, title: "保养计划"}, {id: 3, title: "巡检计划"}],
				step: [{id: 1, title: "单次"}, {id: 2, title: "每周"}, {id: 3, title: "每月"}, {id: 4, title: "每年"}],
				step_time_week: [{id: 1, title: "星期一"}, {id: 2, title: "星期二"}, {id: 3, title: "星期三"}, {id: 4, title: "星期四"}, {id: 5, title: "星期五"}, {id: 6, title: "星期六"}, {id: 0, title: "星期天"}],
				step_time_year: this.stepTimeYear(),
				status: [{id: 1, title: "启用"}, {id: 2, title: "停用"}],
			}
		}
	},
	computed: {
		column() {
			return [
				{label: "ID", prop: "id", sortable: true},
				{label: "计划名称", prop: "title", search: true},
				{label: "计划类型", prop: "type", search: true, type: "option", option: this.column_option.type},
				{label: "设备", prop: "good", search: true, type: 'api', option: {api: good.all, field: 'good_id'}},
				{label: "周期", prop: "step", type: "option", option: this.column_option.step},
				{label: "周期时间", prop: "step_time", type: 'step_time'},
				{label: "状态", prop: "status", type: "option", search: true, option: this.column_option.status},
				{label: "创建时间", prop: "create_time", type: 'datetime', sortable: true},
				{label: "操作", prop: "action", fixed: 'right', type: 'action'},
			]
		}
	},
	methods: {
		stepTimeText(scope, item) {
			let text = scope.row.step_time;
			let step_time = text.split(',');
			let week = [];
			switch (scope.row.step) {
				case 2:
					this.column_option.step_time_week.filter((v, i) => {
						if (inArray(step_time, v.id.toString())) {
							week.push(v.title)
						}
					})
					text = week.join(',')
					break;
				case 3:
					step_time.map((v) => {
						week.push(v + '日')
					})
					text = week.join(',')
					break;
				case 4:
					step_time.map((v) => {
						week.push(this.getTree(v))
					})
					text = week.join(',')
					break;
			}
			return text;
		},
		stepTimeYear() {
			const month = [];
			for (let i = 1; i <= 12; i++) {
				const day = [];
				for (let k = 1; k <= 31; k++) {
					day.push({id: k, title: `${k}日`})
				}
				month.push({id: i, title: `${i}月`, children: day})
			}
			return month;
		},
		getTree(value) {
			const ids = value?.split('-') || [];
			const month = this.column_option.step_time_year[ids[0] - 1].title
			const day = this.column_option.step_time_year[ids[0] - 1].children[ids[1] - 1].title
			return `${month}${day}`
		},
	}
}
</script>
